:root {
	//Colors
	--accent-primary: #006325;
	--accent-primary-d10: #{darken(#006325, 10%)};

	--txt-primary: #2f2f2f;
	--txt-secondary: #006325;
	--txt-tertiary: #fff;

	--bg-primary: #006325;
	--bg-secondary: #bc9159;
	--bg-third: #e3e3e3;


	//Fonts
	--ff-primary: "Open Sans", sans-serif;
	--ff-secondary: "Inter", sans-serif;
	--ff-tertiary: "Rokkitt", serif;

	//Sizes
	--fsz-main-s2: 14px;
	--fsz-main-s1: 16px;
	--fsz-main: 18px;
	--fsz-main-l1: 20px;
	--fsz-main-l2: 24px;

	--fsz-h1: 48px;
	--fsz-h2: 40px;
	--fsz-h3: 24px;
	--fsz-h4: 20px;
	--fsz-h5: 18px;
	--fsz-h6: 16px;

	--wr-s1: 1000px;
	--wr-primary: 1200px;
	--wr-l1: 1032px;
	--wr-l2: 1442px;

	--pd-bl-4: 120px;
	--pd-bl-3: 80px;
	--pd-bl-2: 56px;
	--pd-bl-1: 32px;

	--gap-6: 64px;
	--gap-5: 40px;
	--gap-4: 32px;
	--gap-3: 24px;
	--gap-2: 16px;
	--gap-1: 8px;

	--br-1: 4px;

	//shadows
	--sh-block: 21px 29px 58.9px 0px rgba(113, 113, 113, .25);

	// Animations
	--tr-primary: .3s ease-in;
	--tr-secondary: .35s linear;
	--tr-tertiary: .2s ease-in;
}

// Media queries
$xxxl: 1920px;
$xxl: 1600px;
$xl: 1440px;
$l: 1280px;
$m: 1024px;
$s: 768px;
$xs: 480px;
$xxs: 350px;

// Paths
$pt-img: "/dist/images";