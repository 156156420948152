.contact {
	font-size: var(--fsz-main-s1);
	margin-top: var(--gap-2);

	.wrapper {
		display: grid;
		grid-template-columns: 424fr 504fr;
		gap: 72px;
	}

	.left {
		padding: 41px 48px;
		border: 1px solid var(--accent-primary);
		font-family: var(--ff-secondary);

		h6 {
			font-weight: 700;
			color: var(--accent-primary);

			&:not(:first-child) {
				margin-top: var(--gap-3);
			}
		}

		p {
			font-weight: 500;
		}
	}

	.form-contact {
		display: grid;
		gap: 19px;
		padding-top: var(--gap-2);
	}

	input,
	select,
	textarea {
		width: 100%;
		font: inherit;
		color: var(--accent-primary);
		padding: 12px 32px;
		background-color: #f2f2f2;
		border: none;

		&::placeholder {
			color: var(--accent-primary);
		}

		&.error {
			border-color: red;
		}
	}

	textarea {
		min-height: 192px;
		resize: none;
	}

	.response-message:empty {
		display: none;
	}

	#hp-wrap {
		position: absolute;
		top: -999px;
		pointer-events: none;
		opacity: 0;
	}

	.btn {
		padding: var(--gap-2);
		background-color: #bc9159;
		color: var(--txt-tertiary);
		text-align: center;
		border-radius: 26px;
		text-transform: uppercase;
		font-weight: 600;
		box-shadow: 0 8px #e3e3e3;
	}

	@media (max-width: $m) {

		.wrapper {
			gap: var(--gap-2);
		}
	}

	@media (max-width: $s) {

		.wrapper {
			grid-template-columns: 1fr;
		}

		.left {
			padding: 32px;
		}
	}
}

.section-map {
	margin-top: var(--pd-bl-3);

	.map-contact {
		min-height: 449px;
	}
}