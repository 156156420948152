#coming_soon_banner {
	position: relative;
	display: block;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	padding: 1em;
	background: #bc9159;
	border-bottom: 2px solid #d6be16;
	/* cursor:pointer; */
	line-height: 1.4;
	z-index: 1;

	p {
		font-size: 16px;
		text-align: center;
		margin: 0;
		padding: 0;
		font-weight: 500;
		color: #212121;

		&.soon_heading {
			font-size: 1.5em;
			line-height: 1em;
			font-weight: 700;
			margin-bottom: 20px;
		}

		span {
			font-weight: 700;
		}
	}
}

.header-main {
	background-color: var(--bg-primary);
	padding: 16px 0;

	.inner-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		nav {
			margin-bottom: 15px;

			.nav-links {
				display: flex;
				align-items: flex-end;

				li {
					position: relative;
					list-style: none;

					&:last-child {
						margin-bottom: -3px;
					}

					&.center {
						text-align: center;
						margin-left: 16px;
					}

					.link-item {
						font-size: var(--fsz-main);
						color: var(--txt-tertiary);
						font-weight: 700;
						padding: 30px 16px;
						text-shadow: 0 3px 1px rgba(0, 0, 0, .3);
					}

					.dropdown {
						transform: scaleY(0);
						position: absolute;
						top: 53px;
						min-width: 100%;
						width: auto;
						z-index: 8;

						.dropdown-item {
							display: inline-block;
							background-color: #006325;
							width: 100%;
							margin-left: 0;

							a {
								display: block;
								position: relative;
								font-size: 17px;
								font-weight: 700;
								color: #fff;
								text-decoration: none;
								max-width: 100%;
								padding: 20px 10px;
								text-align: center;
								text-shadow: 0 3px 1px rgba(0, 0, 0, .3);
								z-index: 2;
							}

							&:before {
								content: "";
								position: absolute;
								display: block;
								height: 100%;
								width: 100%;
								top: 2px;
								background-color: #0f3820;
								transform: scaleY(0);
								transform-origin: bottom;
								transition: transform .3s ease-out;
								z-index: 1;
							}

							&:hover:before {
								transform: scaleY(1);
							}

							&:after {
								content: "";
								top: 1px;
								left: 0;
								right: 0;
								position: absolute;
								display: block;
								height: 2px;
								width: 80%;
								margin: 0 auto;
								background-color: rgba(15, 56, 32, .3);
							}
						}
					}

					&.dropdown-menu:hover {

						.dropdown {
							transform: scaleY(1);
						}
					}

					.social-networks {
						margin-bottom: 8px;
					}

					.pay-bill {
						display: flex;
						background-color: var(--bg-secondary);
						text-shadow: 2.148px 1.289px rgba(0, 71, 27, .40);
						font-size: var(--fsz-main);
						font-weight: bold;
						color: var(--txt-tertiary);
						width: 80px;
						height: 32px;
						border-radius: 20px;
						box-shadow: 0px 5px 1.29px 0px #00471b;
					}
				}
			}
		}

		.mobile_btn_wrap {
			position: absolute;
			top: calc(50% + 8px);
			transform: translateY(-50%);
			right: 25px;
			display: none;
			height: 40px;
			width: 45px;

			.mobile_btn {
				position: relative;
				width: 45px;
				height: 5px;
				background-color: #bc9159;
				top: 25%;
				transform: translateY(-50%);

				&::before,
				&::after {
					content: "";
					position: relative;
					display: block;
					height: 5px;
					width: 100%;
					background-color: #bc9159;
				}

				&::after {
					top: 9px;
				}

				&::before {
					bottom: 14px;
				}
			}
		}
	}

	.mobile-menu {
		display: none;

		.exit-mobile {
			display: none;
		}
	}
}

/***>>Media Queries***/
@media only screen and (max-width: $m) {

	.header-main {

		.inner-header {
			align-items: center;

			.logo {
				width: 30%;
			}

			nav {

				.nav-links {

					li {

						.link-item,
						.pay-bill {
							font-size: var(--fsz-main-s1);
						}

						.link-item {
							padding: 30px 12.5px;
						}

						&.center {
							margin-left: 12.5px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 800px) {

	#coming_soon_banner {

		p {
			font-size: 14px;
		}
	}

	.header-main {

		.inner-header {
			position: relative;

			.logo {
				width: 75%;
			}

			.mobile_btn_wrap {
				display: block;
			}

			nav {
				display: none;
			}
		}

		.mobile-menu {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			background: var(--bg-primary);
			height: 100%;
			width: 0;
			overflow-y: auto;
			text-align: center;
			transition: .2s all linear;
			z-index: 25;

			&.active {
				width: 100%;
			}


			ul {
				padding: 55px 0px 30px 0px;


				.social-networks {
					display: none;
				}

				a {
					display: block;
					padding: 10px 0;
					color: white;
					font-size: 17px;
					font-weight: 700;
				}
			}

			.exit-mobile {
				position: fixed;
				right: 10px;
				top: 10px;
				font-size: 45px;
				color: #fff;
				height: 45px;
				width: 45px;

				&.active {
					display: inline-block;
				}
			}
		}
	}
}

@media only screen and (max-width: $xs) {

	#coming_soon_banner {

		p {
			font-size: 12px;
		}
	}
}