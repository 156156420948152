.faqs {
	line-height: 1.9;

	.wrapper {
		display: grid;
		grid-template-columns: 1fr 420px;
		align-items: start;
		justify-content: space-between;
		gap: var(--gap-4);
	}

	.left {
		max-width: 560px;
		margin-right: auto;
	}

	.right {
		display: grid;
		place-items: center;
		background-color: var(--accent-primary);
		color: var(--txt-tertiary);
		text-align: center;
		border-radius: 25px;
		overflow: hidden;
		padding-top: var(--pd-bl-3);

		h3 {
			color: inherit;
			letter-spacing: 2px;
			font-weight: 700;
		}

		p {
			color: inherit;
			font-size: var(--fsz-main);
			margin-top: var(--gap-5);
			line-height: 1.2;
		}
	}

	.inner {
		max-width: min(320px, 95%);
	}

	.btn {
		max-width: 236px;
		margin: var(--gap-5) auto -20px;
		padding: 20px;
		background-color: #fff;
		color: var(--accent-primary);
		text-align: center;
		border-radius: 70px;
		font-weight: 700;
		box-shadow: 0 6px 8px rgba(0, 0, 0, .2);
		position: relative;
		z-index: 10;
		text-decoration: none;

		&:hover {
			background-color: var(--accent-primary-d10);
			color: var(--txt-tertiary);
		}
	}

	.wrapper-image {
		background-position: center;
		background-size: cover;
	}

	@media (max-width: $m) {

		.wrapper {
			grid-template-columns: 1fr 340px;
		}
	}

	@media (max-width: $s) {

		.wrapper-image {
			display: none;
		}

		.wrapper {
			grid-template-columns: 1fr;
		}

		.left {
			max-width: unset;
		}

		.right {
			border-radius: 0;
			overflow: visible;
			padding-top: var(--pd-bl-2);
			margin-inline: -2.6%;

			h3 {
				font-size: var(--fsz-main-l1);
			}

			p {
				font-size: var(--fsz-main-s2);
				margin-top: var(--gap-1);
			}
		}

		.btn {
			margin-top: var(--gap-4);
		}
	}
}