:where(
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video
) {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: top;
	font-family: inherit;
	font-weight: inherit;
}

/* HTML5 display-role reset for older browsers */

:where(
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section
) {
	display: block;
}

:where(html, body) {
	min-width: 375px !important;
	height: 100%;
	width: 100%;
}

:where(ol, ul) {
	list-style: none;
}

:where(blockquote, q) {
	quotes: none;
}

:where(blockquote:before, blockquote:after, q:before, q:after) {
	content: "";
	content: none;
}

:where(table) {
	border-collapse: collapse;
	border-spacing: 0;
}

:where(*, *::before, *::after) {
	box-sizing: border-box;
}
