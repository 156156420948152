.privacy, .accessibility {

	h2,
	h3 {
		font-weight: 400;
	}

	p {
		margin-top: var(--gap-2);
	}

	ul {
		padding-left: 2em;
		list-style-type: circle;
	}

	li:not(:first-of-type) {
		margin-top: var(--gap-2);
	}
}

/***>>Media Queries***/
@media only screen and (max-width: $xxs) {

	.privacy {

		a {
			word-break: break-all;
		}
	}
}