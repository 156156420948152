ai-select {
	--select-min-height: 2em;
	// --select-pad-x: 9px;
	--select-icon-w: 1.5em;
	// --select-border-w: 1px;
	--select-fs: 18px;
	// --options-pad-y: 16px;
	--options-fs: 18px;
	// --options-max-display-items: 5;
	// --option-pad-y: 12px;

	--select-border: transparent;
	--select-hover-border: var(--accent-primary);
	--select-opened-border: var(--accent-primary);
	// --select-opened-bg: $white_dark;
	// --select-opened-color: var(--primary-background);
	--option-hover-bg: var(--accent-primary);

	box-sizing: border-box;
	min-width: var(--mwidth, unset);
	user-select: none;
	position: relative;
	z-index: 10;

	&[opened] {

		&::part(options) {
			opacity: 1;
			visibility: visible;
		}

		&::part(base)::after {
			rotate: 180deg;
		}
	}

	&::part(select) {
		box-sizing: border-box;
		position: relative;
	}

	&::part(base) {
		box-sizing: border-box;
		white-space: nowrap;
		font-size: var(--select-fs, 1em);
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: .5em;
		padding-inline: var(--select-pad-x, .5em);
		height: var(--select-min-height, 2.5em);
		cursor: pointer;
		border-width: var(--select-border-w, 1px);
		border-style: solid;
		border-color: var(--select-border, black);
		transition: all var(--tr-primary, .35s ease-in-out);

		&::after {
			flex-shrink: 0;
			width: var(--select-icon-w, 1em);
			height: 100%;
			display: inline-flex;
			content: '';
			background-image: url('/dist/images/icons/chevron.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transform-origin: center;
			filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, .15));
			transition: rotate var(--tr-primary, .35s ease-in-out);
		}
	}

	&::part(options) {
		font-size: var(--options-fs, 1em);
		box-sizing: border-box;
		margin: 0;
		padding: var(--options-pad-y, 0) 0;
		list-style: none;
		position: absolute;
		z-index: 15;
		top: calc(100% - var(--select-border-w, 1px));
		left: 0;
		width: 100%;
		border-width: var(--select-border-w, 1px);
		border-style: solid;
		max-height: calc(var(--options-pad-y, 0) * 2 + (var(--option-pad-y, .5em) * 2 + 1em) * var(--options-max-display-items, 5));
		overflow-y: auto;
		scrollbar-color: var(--primary-background) transparent;
		transition: all var(--tr-primary, .35s ease-in-out);
		opacity: 0;
		visibility: hidden;
	}

	&::part(option) {
		box-sizing: border-box;
		display: block;
		text-transform: capitalize;
		padding: var(--option-pad-y, .5em) var(--select-pad-x, .5em);
		cursor: pointer;
		transition: background-color var(--tr-primary, .35s ease-in-out);

		&:hover {
			background-color: var(--option-hover-bg, lightblue);
		}
	}

	&:hover {

		&::part(base) {
			border-color: var(--select-hover-border, lightblue);
		}
	}

	&:focus-within,
	&[opened] {
		filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, .15));

		&::part(base) {
			border-color: var(--select-opened-border, lightblue);
			border-bottom-color: var(--select-opened-bg, white);
			background-color: var(--select-opened-bg, white);
			color: var(--select-opened-color, black);
		}

		&::part(options) {
			border-color: var(--select-opened-border, lightblue);
			border-top: none;
			background-color: var(--select-opened-bg, white);
			color: var(--select-opened-color, black);
		}
	}
}