//Rates top

.rates-top {
	border-bottom: 8px solid #9e794c;
	padding: 40px 0 39px;

	.inner-rates-top {
		display: flex;


		// Slider style

		.slider {
			margin-right: 116px;
			flex: 0 0 560px;
			height: max-content;
			border-radius: 20px;
			overflow: hidden;

			.info-slider-item {
				opacity: 1;

				img {
					width: 100%;
				}
			}

			.slider-dots {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--bg-primary);
				padding: 6px 0;

				.slider-dot {
					height: 14px;
					width: 14px;
					background: #e6f0ea;
					border-radius: 100%;
					opacity: .6;
					margin: 0 7.7px;

					&.tns-nav-active {
						opacity: 1;
					}
				}
			}
		}

		//Info style

		.open-info {
			display: none;
		}

		.info {

			h1 {
				color: var(--txt-secondary);
				font-size: 36px;
				font-weight: bold;
			}

			//Info list

			.info-list {
				display: flex;
				flex-wrap: wrap;
				margin-top: 47px;
				font-family: var(--ff-secondary);

				.info-item {
					flex: 0 0 42%;
					margin-right: 40px;
					margin-bottom: 24px;

					p {
						font-size: var(--fsz-main);
						font-weight: 700;
					}

					span {
						font-size: var(--fsz-main-s1);
						font-weight: 500;
					}
				}
			}
		}
	}
}


//Results section

.results {
	position: relative;


	.inner-results {
		position: relative;
		display: flex;


		.facility-filters {
			margin-left: 16px;
			display: flex;
			align-items: center;
			gap: 1px;

			flex-shrink: 0;
		}

		.facility-filter {
			background-color: var(--accent-primary);
			border: 1px solid var(--accent-primary);
			padding-inline: 40px;
			padding-block: 7px;
			color: var(--txt-tertiary);
			white-space: nowrap;
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: 400;
			cursor: pointer;
			text-transform: capitalize;

			input {
				all: unset;
			}
			transition: all var(--tr-primary);


			&:hover,
			&:has(:checked) {
				border: 1px solid var(--accent-primary);
				background-color: #fff;
				color: var(--accent-primary);
			}

			&:first-child {
				border-radius: 17px 0px 0px 17px;
			}

			&:last-child {
				border-radius: 0px 17px 17px 0px;
			}
		}



		.tab {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			box-sizing: border-box;
			background: var(--bg-primary);
			width: 30%;
			padding: 47px 0 61px;

			li {
				border: 1px solid var(--txt-tertiary);
				border-top-left-radius: 29.5px;
				border-bottom-left-radius: 29.5px;
				border-right: none;
				padding: 18px 28px;
				color: var(--txt-tertiary);
				margin-bottom: 24px;
				font-size: 15px;
				width: 45%;
				cursor: pointer;
				transition: .2s all linear;

				&.active {
					background: var(--txt-tertiary);
					color: var(--bg-primary);
				}

				&:hover:not(.active) {
					background-color: rgba(255, 255, 255, .2);
				}
			}
		}

		.content {
			width: 70%;

			p {
				font-size: var(--fsz-main-s2);
				color: var(--bg-secondary);
				margin: 15px 0;
				margin-left: 16px;
			}

			ul {
				display: flex;
				margin-left: 16px;

				li {
					font-size: 12px;
					background: var(--bg-primary);
					color: var(--txt-tertiary);
					padding: 8px 24px;
					margin: 0 1px;
					border: 1px solid transparent;
					cursor: pointer;
					transition: .2s all linear;

					&:hover {
						background: #007d2f;
					}

					&.active {
						color: var(--bg-primary);
						background: var(--txt-tertiary);
						border-color: var(--bg-primary);
					}

					&:first-child {
						border-top-left-radius: 17px;
						border-bottom-left-radius: 17px;
					}

					&:last-child {
						border-top-right-radius: 17px;
						border-bottom-right-radius: 17px;
					}
				}
			}


			h4 {
				font-size: var(--fsz-main-l2);
				color: #888;
				font-weight: bold;
				margin: 16px;
			}


			.thead {
				display: grid;
				background: #d9d9d9;
				padding: 16px 0px 16px 24px;
				grid-template-columns: repeat(5, 312fr);

				.th {
					text-align: center;
					font-size: var(--fsz-main-s2);
					color: #000;
				}
			}

			.coming_soon {
				margin: 50px auto;
				text-align: center;
				font-size: 26px;
				font-weight: 600;
			}

			.tbody {

				margin-left: 24px;

				.row {
					display: none;
					padding: 16px 0;
					grid-template-columns: repeat(5, 312fr);
					border-bottom: 1px solid #9e794c;

					&.active {
						display: grid;
					}

					&:last-child {
						border-bottom: none;
					}

					.td {
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						color: #646464;
						font-size: 12px;
						font-weight: bold;
						padding: 20px 0;

						&.size {
							font-size: 20px;
							color: var(--accent-primary);
							font-weight: 700;
						}

						&.offer {
							font-size: var(--fsz-main-s2);
						}

						&.rent {
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 2px;
							font-size: 21px;
							color: #000;

							.old {
								font-size: 12px;
								text-decoration: line-through;
							}
						}

						&.details {
							justify-content: flex-start;
							align-items: flex-start;
							padding-left: 10px;
							text-align: left;
							flex-direction: column;
							font-size: 12px;
							font-weight: 700;

							.utn {
								text-transform: uppercase;
								font-size: 14px;
							}

							ol {
								list-style: disc;
								margin-left: 1.25em;
							}
						}

						a {
							padding: 7px 40px;
							background-color: #0f3820;
							color: white;
							font-size: 13px;
							border-radius: 20px;
						}
					}
				}
			}

			span.info {
				display: block;
				margin-top: 45px;
				color: #000;
				font-size: 10px;
				margin-left: 32px;
				margin-bottom: 98px;
			}

			//Site map

			.sitemap {
				width: 100%;
				max-width: 900px;
				margin-top: 108px;
				margin-left: 135px;
				margin-bottom: 148px;
				box-sizing: border-box;
			}


			//Features

			&[data-id="features"] {
				padding-top: 39px;
				padding-left: 56px;

				h1 {
					font-size: 35px;
					color: var(--accent-primary);
					font-family: var(--ff-tertiary);
				}

				.features {
					display: flex;
					margin-left: 60px;
					margin-top: 29px;
					flex-wrap: wrap;
					width: 800px;

					.features-item {
						text-align: center;
						flex: 0 0 25%;

						p {
							font-size: 18px;
							color: #000;
							margin-top: 11px;
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}


@media only screen and (max-width: $xxl) {

	.results {

		.inner-results {

			.content {

				//Site map

				.sitemap {
					margin-top: 50px;
					margin-left: 50px;
				}
			}
		}
	}
}


@media only screen and (max-width: 1500px) {


	.results {



		.inner-results {

			.tab {

				li {
					width: 78%;
				}
			}

			.content {
				width: 100%;

				//Features

				&[data-id="features"] {
					padding: 30px 20px;

					.features {
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						margin-left: 30px;
						width: 90%;
					}
				}
			}
		}
	}
}


@media only screen and (max-width: $xl) {

	.results {

		.inner-results {

			.content {

				//Site map

				.sitemap {
					width: 800px;
					max-width: 100%;
				}
			}
		}
	}
}

@media only screen and (min-width: 1101px) and (max-width: 1237px) {

	.rates-top {

		.inner-rates-top {

			.info {

				.info-list {

					.info-item {
						flex: 0 0 70%;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1100px) {

	.rates-top {

		.inner-rates-top {
			flex-wrap: wrap;
			justify-content: center;

			.slider {
				flex: 0 0 490px;
				margin-right: 0;
			}

			.info {
				margin-top: 30px;
			}
		}
	}


	.results {

		.inner-results {
			flex-wrap: wrap;

			.tab {
				width: 100%;
				padding: 0;
				flex-direction: row;

				li {
					border-radius: inherit;
					border-top: none;
					border-bottom: none;
					margin-bottom: inherit;
				}
			}

			.content {

				// .thead,
				// .tbody .row {
				// 	grid-template-columns: auto auto auto auto auto;
				// }

				.thead {
					padding: 16px 0;
				}

				.tbody {
					width: 100%;
					margin-left: 0;

					.row {

						.td {
							font-size: 10px;

							a {
								font-size: 10px;
								padding: 7px 20px;
							}
						}
					}
				}


				.sitemap {
					margin-left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {

	.rates-top {

		.inner-rates-top {

			.slider {
				flex: 0 0 280px;
			}

			.open-info {
				display: block;
				background-color: var(--bg-primary);
				position: relative;
				width: 100%;
				text-align: center;
				padding: 10px 15px 25px;

				p {
					color: #fff;
					font-weight: 700;
					font-size: 18px;
				}

				span {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%) rotate(-90deg);
					color: #fff;
					font-size: 28px;
				}
			}

			.info {

				h1 {
					font-size: 25px;
					text-align: center;
				}

				.info-list {
					//justify-content: center;
					align-items: center;
					height: 0;
					transition: height .3s ease;
					overflow: hidden;
					margin-top: 47px;

					.info-item {
						flex: 0 0 100%;
						text-align: center;
					}
				}
			}
		}
	}


	.results {

		.inner-results {

			.content {

				//Features

				&[data-id="features"] {
					padding: 30px 20px;

					h1 {
						text-align: center;
					}

					.features {
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						width: 100%;
						margin-left: 0;

						.features-item {
							flex: 0 0 50%;
							margin-bottom: 55px;

							img {
								max-width: 80px;
							}

							p {
								font-size: 16px;
							}
						}
					}
				}

				.tbody {

					.row {

						.td {

							&.details {
								font-size: 11px;
								padding-left: 0;
								text-align: left;

								ol {
									list-style: disc;
									margin-left: 1.25em;
								}
							}

							&.offer {
								color: #006325;
								font-size: 12px;
							}

							&.rent {
								flex-direction: column;
							}

							a {
								padding: 7px 10px;
							}
						}
					}
				}
			}

			.facility-filter {
				padding-inline: 15px;
			}
		}
	}
}

@media only screen and (max-width: 480px) {

	.results {

		.inner-results {

			.facility-filters {
				display: flex;
				align-items: center;
				gap: 1px;
				flex-direction: column;
				width: 95%;
				margin-inline: auto;
				flex-shrink: 0;
			}

			.facility-filter {
				width: 100%;
				background-color: var(--accent-primary);
				border: 1px solid var(--accent-primary);
				padding-inline: 40px;
				padding-block: 7px;
				color: var(--txt-tertiary);
				white-space: nowrap;
				display: flex;
				align-items: center;
				font-size: 12px;
				font-weight: 400;
				text-align: center;
				cursor: pointer;
				text-transform: capitalize;

				span {
					margin-inline: auto;
				}

				input {
					all: unset;
				}
				transition: all var(--tr-primary);


				&:hover,
				&:has(:checked) {
					border: 1px solid var(--accent-primary);
					background-color: #fff;
					color: var(--accent-primary);
				}

				&:first-child {
					border-radius: 0px;				}

				&:last-child {
					border-radius: 0px;
				}
			}

			.tab {

				li {
					font-size: 12px;
					padding: 18px 0;
					text-align: center;
				}
			}

			.content {

				ul {
					flex-direction: column;
					border-radius: 17px;
					overflow: hidden;

					li {
						border-radius: 0;

						&:first-child,
						&:last-child {
							border-radius: 0;
						}
					}
				}

				.thead {

					.th {

						span {
							display: none;
						}
					}
				}

				// .thead,
				// .tbody .row {
				// 	grid-template-columns: 15% 25% 20% 20% 20%;
				// }
			}
		}
	}
}