/*******************
* Reusable Styles
********************/

html {
	font-family: var(--ff-primary);
	font-size: var(--fsz-main);
	color: var(--txt-primary);
	scroll-behavior: smooth;
	overflow-y: hidden;
	line-height: var(--line-height, normal);
	accent-color: var(--accent-primary);
}

body {
	overflow-x: hidden;
	scrollbar-color: var(--txt-primary) var(--primary-background);
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: var(--primary-background);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--txt-primary);
		border-radius: var(--br-primary);

		&:hover {
			background: var(--primary-background);
		}
	}
}

:where(h1, h2, h3, h4, h5, h6) {
	margin: 0;
}

:where(h1) {
	font-size: var(--fsz-h1);
	font-weight: 700;
}

:where(h2) {
	font-size: var(--fsz-h2);
	font-weight: 700;
}

:where(h3) {
	font-size: var(--fsz-h3);
	font-weight: 600;
}

:where(h4) {
	font-size: var(--fsz-h4);
	font-weight: 500;
}

:where(h5) {
	font-size: var(--fsz-h5);
	font-weight: 600;
}

:where(h6) {
	font-size: var(--fsz-h6);
	font-weight: 400;
}

:where(a) {
	color: inherit;
	align-items: center;
	justify-content: center;
	gap: .5em;
	text-decoration: none;
	transition: all var(--tr-primary);
}

:where(button) {
	font: inherit;
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
	transition: all var(--tr-primary);
}

:where(svg) {
	fill: currentColor;
	stroke: currentColor;
}

:where(img) {
	max-width: 100%;
}

/*** Classes ***/

#container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
}

.wrapper {
	max-width: min(var(--wr-primary), 95%);
	margin-inline: auto;

	&[data-wr="l1"] {
		max-width: min(var(--wr-l1), 95%);
	}

	&[data-wr="l2"] {
		max-width: min(var(--wr-l2), 95%);
	}

	&[data-wr="s1"] {
		max-width: min(var(--wr-s1), 95%);
	}
}

.tns-outer {
	overflow: hidden;
}

.tns-visually-hidden {
	display: none;
}

.tns-item {
	display: inline-flex;
	transition: opacity var(--tr-primary);

	&[aria-hidden="true"] {
		opacity: 0;
	}
}

.slider-arrow {

	&[disabled] {
		opacity: .2;
	}
}

.overlay {
	position: fixed;
	z-index: 100;
	inset: 0;
	background-color: rgba(#000000, .7);
	backdrop-filter: blur(5px);
	cursor: pointer;
	transition: all var(--tr-primary);
	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 1;
		visibility: visible;
	}
}

.slider-dots {
	display: flex;
	align-items: center;
	gap: var(--gap-1);

	.slider-dot {
		color: var(--txt-secondary);
		padding: 0;

		&:hover,
		&.tns-nav-active {
			color: var(--txt-primary);
		}
	}
}

.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all var(--tr-primary);
}