[data-template="default"] {
	padding-bottom: var(--pd-bl-2);
	font-size: var(--fsz-main-s1);

	h2 {
		color: var(--accent-primary);
	}

	h3,
	h6 {
		font-weight: 700;
		color: var(--accent-primary);

		&:not(:first-child) {
			margin-top: var(--gap-4);
		}
	}

	p {
		color: #333333;
	}

	ul {
		margin-top: var(--gap-1);
		padding-left: 1.5em;
		list-style: outside disc;
	}

	a {
		color: #006325;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}