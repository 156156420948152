.guide {
	padding-bottom: var(--pd-bl-2);

	#wf-bars {
		width: 440px;
	}

	#wf-items {
		position: relative;
	}

	.wf-left {
		overflow: visible;

		.wf-details {
			line-height: 18px;
		}
	}

	.wf-left a {
		min-width: 260px;
		min-height: 50px;
		margin: 0 auto !important;
		border-radius: 50px;
		display: flex;
		font-family: var(--ff-primary);
		font-size: var(--fsz-main);
		font-weight: 400;
		text-transform: uppercase;
	}
}

/* ***** Media Queries ***** */

@media screen and (min-width: 451px) and (max-width: 590px) {

	.guide {

		#wf-nav {

			li {

				a {
					font-size: 17px;
				}
			}
		}
	}
}