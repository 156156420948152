.estimator {

	.wrapper {
		max-width: var(--wr-primary);
	}

	#sc-button {
		min-width: 260px;
		min-height: 50px;
		margin-top: -12px !important;
		margin-bottom: 30px !important;
		border-radius: 50px;
		display: inline-flex;
		font-family: var(--ff-primary);
		font-size: var(--fsz-main);
		font-weight: 400;
		text-transform: uppercase;
	}

	@media (min-width: 451px) and (max-width: 550px) {

		#sc-top > a > h2 {
			font-size: 15px;
		}
	}

	@media (max-width: $xs) {

		#sc-button {
			min-height: 50px;
			margin-top: 16px !important;
			margin-bottom: -10px !important;
		}
	}
}