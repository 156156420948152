.section-page-title {
	font-family: var(--ff-tertiary);
	color: var(--accent-primary);
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;

	.wrapper {
		padding: var(--gap-5);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-title {
		min-width: min(466px, 100%);
		padding: var(--gap-1);
		line-height: 1;
		border-radius: 32px;
		border: 2px solid var(--accent-primary);
	}

	@media (max-width: $s) {

		.page-title {
			min-width: min(300px, 100%);
			font-size: 28px;
		}
	}
}