//Footer

footer {
	margin-top: auto;

	//Footer nav

	.footer-nav {
		text-align: center;

		ul {
			display: inline-flex;
			justify-content: center;
			flex-wrap: wrap;
			width: 890px;

			li {
				margin: 21px 19px 27px;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				a {
					color: var(--txt-secondary);
					font-size: var(--fsz-main-s2);
				}
			}
		}
	}


	//Footer bottom

	.footer-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--bg-third);
		padding: 16px 0;
		color: var(--txt-secondary);
		font-size: var(--fsz-main-s2);

		img {
			margin-left: 10px;
		}
	}
}

/***>>Media Queries***/
@media only screen and (max-width: $m) {


	footer {

		.footer-nav {

			ul {
				width: 800px;

				li {

					a {
						font-size: 12px;
					}
				}
			}
		}
	}
}


@media only screen and (max-width: $s) {


	footer {

		.footer-nav {

			ul {
				display: none;
			}
		}
	}
}