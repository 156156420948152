// Cover style

.cover {
	position: relative;
	background-image: url('/dist/images/home/cover.jpg');
	background-image: -webkit-image-set(
		url('/dist/images/home/cover.webp'));
	background-image: image-set(
		url('/dist/images/home/cover.webp') type('image/webp'));
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 168px 0 240px;

	&::after {
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .30);
	}


	.inner-cover {
		position: relative;
		text-align: center;
		z-index: 1;

		p {
			color: var(--txt-tertiary);
			font-size: var(--fsz-main-l2);
			font-weight: bold;
		}

		img {
			margin-top: 40px;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--bg-secondary);
			color: var(--txt-tertiary);
			border-radius: 102px;
			width: 380px;
			height: 56px;
			font-size: var(--fsz-main-l2);
			font-weight: bold;
			margin-left: 50%;
			transform: translateX(-50%);
			margin-top: 40px;
		}
	}
}


// About us style

.about-us {
	margin-block: 64px;

	.inner-about-us {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.info,
		.address {
			flex: 0 0 45%;
		}

		.info {

			h1 {
				font-size: 35px;
				color: var(--txt-secondary);
				font-family: 'Rokkitt', serif;
			}

			p {
				font-size: var(--fsz-main-s1);
				margin-top: 15px;
			}
		}

		.address {

			// Slider style

			.slider {
				flex: 0 0 560px;
				border-radius: 20px;
				overflow: hidden;

				img {
					margin-top: 0;
				}

				.slider-dots {
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: var(--bg-primary);
					padding: 6px 0;

					.slider-dot {
						height: 14px;
						width: 14px;
						background: #e6f0ea;
						border-radius: 100%;
						opacity: .6;
						margin: 0 7.7px;

						&.tns-nav-active {
							opacity: 1;
						}
					}
				}
			}

			.info-slider-item {
				opacity: 1;
			}

			.contact-info {
				display: flex;
				flex-wrap: wrap;
				margin-top: 47px;
				font-family: var(--ff-secondary);

				.contact-item {
					flex: 0 0 42%;
					margin-right: 40px;
					margin-bottom: 24px;

					p {
						font-size: var(--fsz-main);
						font-weight: 700;
					}

					span {
						font-size: var(--fsz-main-s1);
						font-weight: 500;
					}
				}
			}
		}

		img {
			margin-top: 62px;
		}
	}
}


/***>>Media Queries***/
@media only screen and (max-width: $m) {

	//Cover

	.cover {
		padding: 50px 0;

		img {
			width: 30%;
		}

		.inner-cover {

			a {
				font-size: var(--fsz-main);
				width: 300px;
				height: 50px;
			}
		}
	}
}

@media only screen and (max-width: 1169px) {

	.about-us {

		.inner-about-us {

			.address {

				.contact-info {
					justify-content: space-between;

					.contact-info {
						margin-right: 0;
					}

					.contact-item {
						flex: 0 0 70%;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 800px) {

	.about-us {

		.inner-about-us {

			.info,
			.address {
				flex: 0 0 100%;
			}

			.address {
				margin-top: 50px;

				.slider {

					img {
						border-radius: 20px;
					}
				}
			}
		}
	}
}


@media only screen and (max-width: $s) {

	//Cover

	.cover {
		padding: 30px 0;

		img {
			width: 30%;
			margin-top: 20px;
		}

		.inner-cover {

			p {
				font-size: var(--fsz-main);
				margin-top: 20px;
			}


			a {
				margin-top: 20px;
				font-size: var(--fsz-main-s1);
				width: 220px;
				height: 40px;
			}
		}
	}
}